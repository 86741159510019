import React from 'react';
import {withSiteData} from 'react-static';
import Prismic from 'prismic-javascript';
import {Link} from '@reach/router';
import PrismicPreview from '../prismic-preview';
import PrismicLink from '../prismic-link';
import './header.css';

const Header = props => (
  <PrismicPreview
    publishedContent={props}
    query={{
      predicates: Prismic.Predicates.at('document.type', 'content_settings')
    }}
  >
    {({main_navigation}) => (
      <header className="page-header">
        <div className="rd-navbar-wrap">
          <nav
            className="rd-navbar novi-background bg-cover"
            data-layout="rd-navbar-fixed"
            data-sm-layout="rd-navbar-fixed"
            data-sm-device-layout="rd-navbar-fixed"
            data-md-layout="rd-navbar-fixed"
            data-md-device-layout="rd-navbar-fixed"
            data-lg-device-layout="rd-navbar-fixed"
            data-xl-device-layout="rd-navbar-static"
            data-xxl-device-layout="rd-navbar-static"
            data-lg-layout="rd-navbar-fixed"
            data-xl-layout="rd-navbar-static"
            data-xxl-layout="rd-navbar-static"
            data-stick-up-clone="false"
            data-sm-stick-up="true"
            data-md-stick-up="true"
            data-lg-stick-up="true"
            data-xl-stick-up="true"
            data-xxl-stick-up="true"
            data-lg-stick-up-offset="69px"
            data-xl-stick-up-offset="1px"
            data-xxl-stick-up-offset="1px"
          >
            <div className="rd-navbar-inner">
              <div className="rd-navbar-panel">
                <button
                  className="rd-navbar-toggle"
                  data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                >
                  <span />
                </button>
                <div className="rd-navbar-brand">
                  <Link className="brand-name" to="/">
                    <img
                      src="/logo-new.svg"
                      className="d-none d-xl-block"
                      alt=""
                      width="90"
                      height="90"
                    />
                    <img
                      src="/mobile-logo.svg"
                      className="d-xl-none"
                      alt=""
                      width="137"
                      height="38"
                    />
                  </Link>
                </div>
              </div>

              <div className="rd-navbar-nav-wrap">
                <div className="rd-navbar-nav-wrap__element d-xl-none">
                  <a className="button btn-secondary" href="#">
                    Book Now
                  </a>
                </div>

                <ul className="rd-navbar-nav">
                  {main_navigation.map(navItem => (
                    <li
                      key={navItem.label}
                      className={
                        navItem.label.toLowerCase() === 'book'
                          ? 'd-none d-xl-inline-block'
                          : ''
                      }
                    >
                      <PrismicLink link={navItem.link}>
                        {navItem.label}
                      </PrismicLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )}
  </PrismicPreview>
);

export default withSiteData(Header);
