import React, {Component} from 'react';
import Prismic from 'prismic-javascript';
import Cookie from 'js-cookie';
import config from '../config';

class PrismicPreview extends Component {
  state = {preview: false, content: {}};

  async componentDidMount() {
    const previewRef = Cookie.get(Prismic.previewCookie);
    if (!previewRef) return;
    const api = await Prismic.api(config.prismic.endpoint);
    const doc = await api.query(
      this.props.query.predicates,
      this.props.query.options || {}
    );
    return this.setState({preview: true, content: {...doc.results[0].data}});
  }

  render() {
    return this.state.preview
      ? this.props.children(this.state.content)
      : this.props.children(this.props.publishedContent);
  }
}

export default PrismicPreview;
