import React from 'react';
import {withSiteData} from 'react-static';
import Prismic from 'prismic-javascript';
import {RichText, Link} from 'prismic-reactjs';
import linkResolver from '../../utils/link-resolver';
import PrismicPreview from '../prismic-preview';
import PrismicLink from '../prismic-link';
import Icon from '../icon';
import './footer.css';

const Footer = props => (
  <PrismicPreview
    publishedContent={props}
    query={{
      predicates: Prismic.Predicates.at('document.type', 'content_settings')
    }}
  >
    {({
      phone_number,
      email_address,
      address,
      twitter_account_link,
      instagram_account_link,
      legal_links
    }) => (
      <footer className="footer-corporate novi-background bg-cover">
        <div className="container">
          <div className="row">
            <div className="col-md order-2 order-md-1 footer__contact">
              <h3>To Book</h3>
              <ul>
                <li>
                  <a href={`tel:${phone_number}`}>
                    <Icon name="phone" /> {phone_number}
                  </a>
                </li>
                <li>
                  <a href={`mailto:${email_address}`}>
                    <Icon name="mail" /> {email_address}
                  </a>
                </li>
              </ul>
              <img
                className="footer__cards d-none d-md-block"
                src="/cards.png"
                alt="Accepted Payment Methods"
                width="189"
                height="20"
              />
            </div>
            <div className="col-md order-1 order-md-2">
              <img
                className="footer__logo"
                src="/logo.png"
                width="102"
                height="82"
                alt="At Action"
              />
            </div>
            <div className="col-md order-3 footer__address">
              <h3>Where to find us</h3>
              {RichText.render(address, linkResolver)}
              <div className="row footer__socials">
                <div className="col">
                  <ul>
                    <li>
                      <a
                        href={Link.url(twitter_account_link, linkResolver)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={Link.url(instagram_account_link, linkResolver)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon name="instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col d-md-none">
                  <img
                    className="footer__cards"
                    src="/cards.png"
                    alt="Accepted Payment Methods"
                    width="189"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row footer__legal">
            <div className="col-8">
              <ul className="footer__legal-links">
                {legal_links.map(navItem => (
                  <li key={navItem.label}>
                    <PrismicLink link={navItem.link}>
                      {navItem.label}
                    </PrismicLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-4 footer__copyright">
              @{new Date().getFullYear()}
            </div>
          </div>
        </div>
      </footer>
    )}
  </PrismicPreview>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default withSiteData(Footer);
