import React from 'react';
import {string} from 'prop-types';

const Icon = ({name}) => {
  if (name === 'mail') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="16"
        viewBox="0 0 15 16"
        className="icon"
      >
        <defs>
          <clipPath id="mail-b">
            <rect width="15" height="16" />
          </clipPath>
        </defs>
        <g id="a" clipPath="url(#mail-b)">
          <path
            d="M6.241,0,0,4.56v9.509H12.482V4.56ZM4.864,9.425.845,12.744v-6.7Zm.659.557.659-.549.051-.042.051.042.659.549,3.927,3.243H1.6Zm2.086-.557,4.02-3.378v6.7Zm4-4.459L6.95,8.876l-.709-.591-.709.591L.87,4.966,6.241,1.047Z"
            transform="translate(1.149 0.473)"
            fill="#e5970c"
          />
        </g>
      </svg>
    );
  }

  if (name === 'phone') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        className="icon"
      >
        <defs>
          <clipPath id="phone-b">
            <rect width="16" height="15" />
          </clipPath>
        </defs>
        <g id="a" clipPath="url(#phone-b)">
          <path
            d="M13.475,9.329c-.085-.142-.3-.227-.639-.383s-1.974-.966-2.272-1.079-.525-.17-.753.156A15.562,15.562,0,0,1,8.761,9.329c-.2.227-.383.256-.724.085a9.241,9.241,0,0,1-2.67-1.661,9.666,9.666,0,0,1-1.846-2.3c-.2-.327-.014-.511.142-.682s.327-.383.5-.582a1.837,1.837,0,0,0,.327-.554.6.6,0,0,0-.028-.582c-.071-.17-.738-1.8-1.022-2.471S2.9.014,2.684.014C2.5,0,2.272,0,2.059,0a1.257,1.257,0,0,0-.895.412A3.776,3.776,0,0,0,0,3.195,6.391,6.391,0,0,0,1.363,6.631a14.863,14.863,0,0,0,5.68,5.027,16.934,16.934,0,0,0,1.9.7,4.521,4.521,0,0,0,2.087.142,3.445,3.445,0,0,0,2.244-1.576A2.746,2.746,0,0,0,13.475,9.329Z"
            transform="translate(1 1.321)"
            fill="#e5970c"
            fillRule="evenodd"
          />
        </g>
      </svg>
    );
  }

  if (name === 'twitter') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="15"
        viewBox="0 0 17 15"
        className="icon"
      >
        <defs>
          <clipPath id="twitter-b">
            <rect width="17" height="15" />
          </clipPath>
        </defs>
        <g id="a" clipPath="url(#twitter-b)">
          <path
            d="M13.6,6.275c.008.132.008.264.008.4a8.78,8.78,0,0,1-8.84,8.84,8.8,8.8,0,0,1-4.762-1.4c.247.025.494.041.741.041a6.18,6.18,0,0,0,3.856-1.335,3.106,3.106,0,0,1-2.9-2.159,3.433,3.433,0,0,0,.585.058,3.3,3.3,0,0,0,.816-.107,3.111,3.111,0,0,1-2.5-3.048V7.527a3.129,3.129,0,0,0,1.409.387A3.064,3.064,0,0,1,.636,5.344a3.153,3.153,0,0,1,.42-1.565,8.837,8.837,0,0,0,6.41,3.246,3.334,3.334,0,0,1-.082-.709A3.107,3.107,0,0,1,12.755,4.19a6.121,6.121,0,0,0,1.977-.758,3.116,3.116,0,0,1-1.368,1.73,6.244,6.244,0,0,0,1.788-.486A6.491,6.491,0,0,1,13.6,6.275Z"
            transform="translate(0.781 -2.21)"
            fill="#e5970c"
          />
        </g>
      </svg>
    );
  }

  if (name === 'instagram') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        className="icon"
      >
        <defs>
          <clipPath id="instagram-b">
            <rect width="18" height="18" />
          </clipPath>
        </defs>
        <g id="a" clipPath="url(#instagram-b)">
          <g transform="translate(0.551 1)">
            <path
              d="M38.17,1.483c2.2,0,2.463.008,3.328.049a4.722,4.722,0,0,1,1.532.28,2.521,2.521,0,0,1,.947.618,2.521,2.521,0,0,1,.618.947,4.411,4.411,0,0,1,.28,1.532c.041.865.049,1.129.049,3.328s-.008,2.463-.049,3.328A4.721,4.721,0,0,1,44.6,13.1a2.745,2.745,0,0,1-1.565,1.565,4.411,4.411,0,0,1-1.532.28c-.865.041-1.129.049-3.328.049s-2.463-.008-3.328-.049a4.722,4.722,0,0,1-1.532-.28A2.745,2.745,0,0,1,31.744,13.1a4.411,4.411,0,0,1-.28-1.532c-.041-.865-.049-1.129-.049-3.328s.008-2.463.049-3.328a4.722,4.722,0,0,1,.28-1.532,2.522,2.522,0,0,1,.618-.947,2.521,2.521,0,0,1,.947-.618,4.411,4.411,0,0,1,1.532-.28c.865-.041,1.129-.049,3.328-.049M38.17,0c-2.241,0-2.521.008-3.394.049a6.3,6.3,0,0,0-2,.379,4.081,4.081,0,0,0-1.458.947,3.936,3.936,0,0,0-.947,1.458,5.833,5.833,0,0,0-.379,2C29.948,5.718,29.94,6,29.94,8.23s.008,2.521.049,3.394a6.3,6.3,0,0,0,.379,2,4.081,4.081,0,0,0,.947,1.458,3.936,3.936,0,0,0,1.458.947,5.833,5.833,0,0,0,2,.379c.882.041,1.162.049,3.394.049s2.521-.008,3.394-.049a6.3,6.3,0,0,0,2-.379,4.081,4.081,0,0,0,1.458-.947,3.936,3.936,0,0,0,.947-1.458,5.833,5.833,0,0,0,.379-2c.041-.882.049-1.162.049-3.394s-.008-2.521-.049-3.394a6.3,6.3,0,0,0-.379-2,4.081,4.081,0,0,0-.947-1.458A3.936,3.936,0,0,0,43.567.428a5.833,5.833,0,0,0-2-.379C40.683.008,40.4,0,38.17,0Z"
              transform="translate(-29.491)"
              fill="#e5970c"
            />
            <path
              d="M39.025,4.86a4.235,4.235,0,1,0,4.235,4.235A4.233,4.233,0,0,0,39.025,4.86Zm0,6.978a2.743,2.743,0,1,1,2.743-2.743A2.743,2.743,0,0,1,39.025,11.838Z"
              transform="translate(-30.345 -0.856)"
              fill="#e5970c"
            />
          </g>
        </g>
      </svg>
    );
  }

  if (name === 'chevron') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="26"
        viewBox="0 0 16 26"
      >
        <defs>
          <clipPath id="chevron-b">
            <rect width="16" height="26" />
          </clipPath>
        </defs>
        <g id="a" clipPath="url(#chevron-b)">
          <rect width="16" height="26" fill="#fff" />
          <path
            d="M4520.623,682.7l11.134,11.134-11.134,11.134"
            transform="translate(-4518.623 -681)"
            fill="none"
            stroke="#e5970c"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }

  if (name === 'whatsapp') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
      >
        <defs>
          <clipPath id="whatsapp-b">
            <rect width="17" height="17" />
          </clipPath>
        </defs>
        <g id="a" clipPath="url(#whatsapp-b)">
          <g transform="translate(1 1)">
            <path
              d="M12.325,2.106A7.187,7.187,0,0,0,1.019,10.777L0,14.5l3.807-1a7.141,7.141,0,0,0,3.432.873h0a7.2,7.2,0,0,0,7.185-7.185A7.111,7.111,0,0,0,12.325,2.106ZM7.246,13.16h0a5.973,5.973,0,0,1-3.041-.835L3.983,12.2l-2.26.59.605-2.206-.146-.222A5.98,5.98,0,0,1,7.246,1.21a5.976,5.976,0,0,1,5.967,5.982A5.974,5.974,0,0,1,7.246,13.16Zm3.278-4.473C10.348,8.594,9.46,8.165,9.3,8.1s-.283-.092-.406.092-.467.582-.567.7-.207.138-.391.046A4.886,4.886,0,0,1,6.5,8.058a5.214,5.214,0,0,1-1-1.241c-.1-.176-.008-.276.077-.368s.169-.214.26-.322a.991.991,0,0,0,.176-.3.324.324,0,0,0-.008-.314c-.046-.092-.406-.973-.559-1.333s-.291-.306-.4-.306-.23-.008-.345-.008a.691.691,0,0,0-.483.222,2.037,2.037,0,0,0-.628,1.5,3.447,3.447,0,0,0,.735,1.854,8.083,8.083,0,0,0,3.064,2.712,9.135,9.135,0,0,0,1.026.375,2.453,2.453,0,0,0,1.134.069,1.858,1.858,0,0,0,1.21-.85,1.459,1.459,0,0,0,.107-.85C10.823,8.824,10.7,8.778,10.525,8.686Z"
              fill="#e5970c"
              fillRule="evenodd"
            />
          </g>
        </g>
      </svg>
    );
  }

  return null;
};

Icon.propTypes = {
  name: string
};

Icon.defaultProps = {
  name: ''
};

export default Icon;
