import React from 'react';
import {object, node} from 'prop-types';
import {Link} from '@reach/router';
import Prismic from 'prismic-reactjs';
import linkResolver from '../utils/link-resolver';

const PrismicLink = ({link, children, ...props}) => {
  const target = link.target
    ? {target: link.target, rel: 'noopener noreferrer'}
    : {};

  if (link.url && link.url.indexOf('.') === -1) {
    link.url = link.url.replace('https://', '');
  }

  if (/^https?:\/\//.test(link.url)) {
    return (
      <a href={link.url} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link to={Prismic.Link.url(link, linkResolver)} {...target} {...props}>
      {children}
    </Link>
  );
};

PrismicLink.propTypes = {
  link: object.isRequired,
  children: node.isRequired
};

export default PrismicLink;
