import React from 'react';
import {Root, Routes} from 'react-static';
import Header from '../header/header';
import Footer from '../footer/footer';
import './layout.css';

class Layout extends React.Component {
  componentDidMount() {
    setTimeout(() => window.requestAnimationFrame(window.ataction), 0);
    setTimeout(() => window.requestAnimationFrame(window.ataction), 500);
    setTimeout(() => window.requestAnimationFrame(window.ataction), 1000);
    setTimeout(() => window.requestAnimationFrame(window.ataction), 1500);
  }

  render() {
    return (
      <Root>
        <div className="page">
          <Header />
          <Routes />
          <Footer />
        </div>
      </Root>
    );
  }
}

export default Layout;
