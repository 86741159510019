const config = {
  base: {
    instagram: '4094596168.1677ed0.28b477d0bed24d0ab6f4bb975e293a0b'
  },

  production: {
    prismic: {
      endpoint: 'https://ataction.cdn.prismic.io/api/v2'
    }
  },

  development: {
    prismic: {
      endpoint: 'https://ataction-dev.cdn.prismic.io/api/v2'
    }
  }
};

export default {
  ...config.base,
  ...config[process.env.NODE_ENV || 'production']
};
