// Imports


// Plugins
const plugins = [{
  location: "/opt/build/repo",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins