
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../node_modules/react-static/lib/browser/components/Default404'), universalOptions)
const t_1 = universal(import('../src/pages/book.js'), universalOptions)
const t_2 = universal(import('../src/pages/page.js'), universalOptions)
const t_3 = universal(import('../src/pages/preview.js'), universalOptions)
const t_4 = universal(import('../src/pages/services.js'), universalOptions)
const t_5 = universal(import('../src/pages/text-page.js'), universalOptions)
const t_6 = universal(import('../src/pages/page'), universalOptions)
const t_7 = universal(import('../src/pages/text-page'), universalOptions)


// Template Map
export default {
  '../node_modules/react-static/lib/browser/components/Default404': t_0,
'../src/pages/book.js': t_1,
'../src/pages/page.js': t_2,
'../src/pages/preview.js': t_3,
'../src/pages/services.js': t_4,
'../src/pages/text-page.js': t_5,
'../src/pages/page': t_6,
'../src/pages/text-page': t_7
}

export const notFoundTemplate = "../node_modules/react-static/lib/browser/components/Default404"
