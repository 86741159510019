import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import Layout from './components/layout/layout';

export default Layout;

if (typeof document !== 'undefined') {
  const renderMethod = module.hot
    ? ReactDOM.render
    : ReactDOM.hydrate || ReactDOM.render;
  const render = Comp => {
    renderMethod(
      <AppContainer>
        <Comp />
      </AppContainer>,
      document.getElementById('root')
    );
  };

  render(Layout);

  if (module.hot) {
    module.hot.accept('./components/layout/layout', () =>
      render(require('./components/layout/layout').default)
    );
  }
}
