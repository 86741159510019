const linkResolver = doc => {
  if (doc.uid === 'home') {
    return '/';
  }

  if (doc.type === 'services') {
    return '/services';
  }

  if (doc.type === 'book') {
    return '/book';
  }

  return `/${doc.uid}`;
};

export default linkResolver;
